.monday-board-item-feature-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}

.authorization-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}

.how-to-screen-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authorization-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guest-viewer-message-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reminders-screen-board-item-menu-container-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.see-all-board-reminders-screen-board-item-menu-container-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 8px; /* Add some space for the scrollbar */
}


.content-wrapper {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.toast-container {
  position: absolute;
  box-sizing: border-box;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.loading-overlay {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
}

.loading-indicator-container {
  width: 100%;
  height: 100%;
  display: flex;
  direction: column;
  justify-content: center;
  align-items: center;
}

.settings-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  flex-wrap: nowrap;
}

.error-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.privacy-policy-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.privacy-policy-highlight {
  font-style: italic;
}

.bordered-table {
  border-collapse: collapse; /* Ensure borders do not have double lines */
  width: 100%; /* Optional: Full width table */
}

.bordered-table th, .bordered-table td {
  border: 1px solid black; /* Border for table cells and headers */
  padding: 8px; /* Optional: Padding for readability */
  text-align: left; /* Optional: Left-align text */
}

.bordered-table th {
  background-color: #f2f2f2; /* Optional: Background color for headers */
}

.terms-of-service-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

a:hover {
  text-decoration: underline;
}

.section {
  margin-bottom: 40px;
}

.section a {
  display: block;
  margin-top: 10px;
}

.pricing-body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.pricing-title {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.pricing-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.pricing-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  text-align: center;
  position: relative;
}

.recommended {
  border: 2px solid #4caf50;
}

.recommended::before {
  content: "Recommended";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.card-header {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.card-price {
  font-size: 2em;
  color: #333;
  margin: 10px 0;
}

.card-price span {
  font-size: 0.5em;
  color: #777;
}

.card-description {
  margin-bottom: 20px;
  color: #666;
}

.card-features {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.card-features li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.card-features li::before {
  content: "✓";
  color: #4caf50;
  position: absolute;
  left: 0;
}